import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-hdr',
  templateUrl: './hdr.component.html',
  styleUrls: ['./hdr.component.css']
})
export class HdrComponent implements OnInit {


  deviceInfo = null;
  isMobile: boolean = false;

  token_found:boolean = false;
  token_user_name:string = "";

  constructor(
  	private deviceService: DeviceDetectorService
  	) {
  	  this.epicFunction() 
    }

  ngOnInit(): void {
  }


  epicFunction() {
      // console.log('hello `Home` component');
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      // console.log(this.deviceInfo);
      // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
      // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
      // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    }

}