<app-hdr></app-hdr>


<div class="container" style="min-height:710px;">
  
  	<!-- Header -->
  	<div class="row" style="margin-top:20px;">
		<div class="col">
			<span style="color:#CCCCCC;font-family:verdana;font-weight:bold;font-size:18px;padding: 30px 10px 5px 0px;">
		    About Us
		   </span>
		</div>
	</div>


	<!-- Floating Columns -->
	<div class="row" style="margin-top:30px;">
	  
		<div class="col-12">
			 <div id="abouttext" style="padding-left:30px;padding-bottom:30px;text-align:left;float:left" >
                  <p class="springnormal">
                    <span class="springfancy">We</span> are a small group of <span class="springnormalx">nefarious</span> individuals who observed a lack in the <span class="springnormalx">publishing</span> industry's ability to produce <span class="springnormalx">material</span> that truly represented tech culture.  <span class="springfancy">While</span> most publications <span class="springnormalx">focused</span> on documenting the <span class="springnormalx">technical</span>
                    features of languages and networking <span class="springnormalx">protocols</span>, they failed to observe some of the underlying <span class="springnormalx">nuances</span> of that technology. <span class="springfancy">Thus</span> we formed <span class="springfancy">SpringSociety.com</span> with the intent of gathering a
                  <span class="springnormalx">collective</span> of tech industry elites for the
                  <span class="springnormalx">purpose</span> of creating unique <span class="springnormalx">publications</span>; publications that place an <span class="springnormalx">emphasis</span> on 'documenting the undocumented'
                  of the <span class="springnormalx">business</span> of technology. 
                  </p>
              </div>  
		</div>


	</div>

<app-ftr></app-ftr>
