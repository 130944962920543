import { Injectable } from '@angular/core';

import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { TokenManager } from '../_serviceAPI/token-manager.service';

import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
var API_URL = environment.apiUrl;


@Injectable()
export class BookApiService {

  deviceInfo = null;

  headersVendor = this.tokenManager.getTokenVendorString()
   
  constructor(
      private http: HttpClient,
      private tokenManager: TokenManager
    ) { }

  /* Book Details */
  public get_book_details(n_guid:string): Observable<any> {
    var n_url = API_URL + '/v01/GET/book_details';
    const body = {"guid":n_guid}
    return this.http.post(n_url, body,{'headers':this.headersVendor})
  }

  public get_books(): Observable<any> {
    var n_url = API_URL + '/v01/GET/books';
    const body = {"fake_id":'fake'}
    return this.http.post(n_url, body,{'headers':this.headersVendor})
  }
  
  private handleError (error: Response | any) {
    console.error('ApiService::handleError', error);
    return Observable.throw(error);
  }


}