import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BookDataService } from '../../_serviceAPI/book-data.service';
import { Books, url_list } from '../../_model/books';

/* Model */
import { MsgInfo } from '../../_model/msginfo';

@Component({
  selector: 'app-books',
  templateUrl: './books.component.html',
  styleUrls: ['./books.component.css']
})
export class BooksComponent implements OnInit {

	msg_info!: MsgInfo;


  // models
  books: Books[];
  url_list: url_list[];

  page_loading:boolean = true;

  constructor(
  	private http: HttpClient, 
    private bookService:BookDataService
  ) { }

  ngOnInit(): void {


  		this.bookService.getBooks()
	            .subscribe(
	                data => {

	                		if (data['status'][0]['msg_status'] != "success") {
		                	this.page_loading = false;

		                		this.msg_info = data['status'][0]['msg_data']['msg_info'][0];

                        alert("ERROR - " + this.msg_info.description_text);

		                	} else {
				                this.books = data['data'];				                
				                // this.n_total_categories = data['data']['data-meta']['total_books'];
				                // this.n_total_urls = data['data']['data-meta']['total_links'];	

                        	    // this.n_ad_title = data['data']['data-meta']['advert']['title'];
                        		// this.n_ad_desc = data['data']['data-meta']['advert']['description'];
                        		// this.n_ad_url = data['data']['data-meta']['advert']['url'];
                        		// this.n_ad_image_src = data['data']['data-meta']['advert']['image_src'];

                        		this.page_loading = false;
		                	}
	                },
	                error => {
                      this.page_loading = false;
	                    alert("ERROR - " + "a network error occurred");
	                });

  }



}