<app-hdr></app-hdr>


<div class="container" style="min-height:700px;">


	<div *ngIf="page_loading" class="row" style="margin-top:0px;min-height:600px;max-width:1100px;">
		<div class="col-12" style="padding-top: 250px;">				
				<center>
					<mat-spinner mode="indeterminate"></mat-spinner>
				</center>
		</div>
	</div>
	
  
  	<!-- Header -->
  	<div class="row" style="margin-top:20px;">
		<div class="col-12">
			<span style="color:#CCCCCC;font-family:verdana;font-weight:bold;font-size:18px;padding: 30px 10px 5px 0px;">
		    	<a href="/books" class="linkid">Books</a> / {{ book_data.title }}  
		   	</span>
		</div>
	</div>



	<!-- Floating Columns -->
	<div class="row" style="margin-top:30px;">

		<div class="col-xs-12 col-sm-9 col-md-9">
			
			<div class="row">
				<div class="col-xs-12 col-sm-6 col-md-6 col-md-6" style="margin-bottom:40px;">
					<span class="basic_text">Author:&nbsp;<b>{{book_data.author}}</b></span>
					<br/>
					<br/>
					<span class="basic_text">{{book_data.content}}</span>					
				</div>
							
				<div class="col-xs-12 col-sm-6 col-md-6">
					<span class="basic_text"><img src="assets/images/books/{{book_data.imgphoto}}" /></span>
				</div>
			</div>

			<div class="row">
					<div class="col-10"><hr/></div>

					<div class="col-xs-12 col-sm-7 col-md-7">
						<span class="basic_text">Publish Date: {{book_data.pubdate}}</span>
					</div>
					<div class="col-xs-12 col-sm-3 col-md-3">
						<span class="basic_text">Status: {{book_data.availability}}</span>
					</div>


					<div class="col-10"><hr/></div>

					<div class="col-xs-12 col-sm-7 col-md-7">
						<span class="basic_text">HARDCOVER: {{book_data.hcopydim}}</span>
					</div>
					<div class="col-xs-12 col-sm-3 col-md-3">
						<span class="basic_text">ISBN: {{book_data.hcopyisbn}}</span>
					</div>


					<div class="col-10"><hr/></div>

					<div class="col-xs-12 col-sm-7 col-md-7">
						<span class="basic_text">PAPERBACK: {{book_data.scopydim}}</span>
					</div>
					<div class="col-xs-12 col-sm-3 col-md-3">
						<span class="basic_text">ISBN: {{book_data.scopyisbn}}</span>
					</div>


					<div class="col-10"><hr/></div>

					<div class="col-xs-12 col-sm-7 col-md-7">
						<span class="basic_text">DIGITAL EDITION: PDF, EPUB, & MOBI</span>
					</div>
					<div class="col-xs-12 col-sm-3 col-md-3">
						<span class="basic_text">ISBN: {{book_data.dcopyisbn}}</span>
					</div>


					<div class="col-10"><hr/></div>

					<div class="col-xs-12 col-sm-7 col-md-7">
						<span class="basic_text">AUDIO BOOK</span>
					</div>
					<div class="col-xs-12 col-sm-3 col-md-3">
						<span class="basic_text">ISBN: {{book_data.acopyisbn}}</span>
					</div>

			</div>

		</div>



		<div class="col-xs-12 col-sm-3 col-md-3">

				<div class="d-md-none" style="margin-top:40px;">&nbsp; &nbsp;</div>

				<div id="alsotext" style="width:280px;padding-top:10px;padding-bottom:10px;">
			          <span>ALSO AVAILABLE</span>
			    </div>

		        <div id="bookcolumn1" style="width:280px;height:170px;margin-bottom:30px">
		          <img src="assets/images/books/{{book_data.relatedimage1}}" style="float:left;margin:4px;" />
		          <div style="font-size: 11px;padding-top: 10px">{{book_data.reltext1}}</div>
		          <a class="basic_text" href="/details/{{book_data.friendly_guid1}}">more details...</a>
		        </div>

		        <div id="bookdivider" style="border-top: solid .5px #CCCCCC;padding-top:35px;padding-bottom:15px"></div>
		        
		        <div id="bookcolumn2" style="float:left;width:280px;margin-bottom:30px">
		          <img src="assets/images/books/{{book_data.relatedimage2}}" style="float:left;margin:4px;" />
		          <div style="font-size: 11px;padding-top: 10px" >{{book_data.reltext2}}</div>
		          <a class="basic_text" href="/details/{{book_data.friendly_guid2}}">more details...</a>
		        </div>
			
		</div>

	</div>






	<!-- Floating Columns -->
	<div class="row" style="margin-top:30px;display:none;">
	  
		<div class="col-9">
			
			<table width="80%">
			<tr class="detail_row_hdr">
				<td colspan="2">
					<span class="basic_text">Author:&nbsp;<b>{{book_data.author}}</b></span>
					<br/>
					<br/>
					<span class="basic_text">{{book_data.content}}</span>
				</td>
				<td width="30%">
					<span class="basic_text"><img src="assets/images/books/{{book_data.imgphoto}}" /></span>
				</td>
			</tr>
		    </table>


			<table width="80%" style="margin-top:20px;">
		    <tr class="detail_row">
				<td colspan="2">
					<span class="basic_text">Publish Date: {{book_data.pubdate}}</span>
				</td>
				<td width="25%">
					<span class="basic_text">Status: {{book_data.availability}}</span>
				</td>
			</tr>
			<tr class="detail_row">
				<td width="35%">
					<span class="basic_text">HARDCOVER</span>
				</td>
				<td>
					<span class="basic_text">{{book_data.hcopydim}}</span>
				</td>
				<td width="15%">
					<span class="basic_text">ISBN:{{book_data.hcopyisbn}}</span>
				</td>
			</tr>
			<tr class="detail_row">
				<td width="15%">
					<span class="basic_text">PAPERBACK</span>
				</td>
				<td>
					<span class="basic_text">{{book_data.scopydim}} </span>
				</td>
				<td width="15%">
					<span class="basic_text">ISBN:{{book_data.scopyisbn}}</span>
				</td>
			</tr>
			<tr class="detail_row">
				<td colspan="2">
					<span class="basic_text">DIGITAL EDITION: PDF, EPUB, & MOBI</span>
				</td>
				<td>
					<span class="basic_text">ISBN:{{book_data.dcopyisbn}}</span>
				</td>
			</tr>
			<tr class="detail_row">
				<td colspan="2">
					<span class="basic_text">AUDIO BOOK</span>
				</td>
				<td>
					<span class="basic_text">ISBN:{{book_data.acopyisbn}}</span>
				</td>
			</tr>
			</table>

		</div>


		<div class="col-3">
			
			<div id="alsotext" style="width:280px; padding-top:13px;padding-bottom:10px">
		          <span>ALSO AVAILABLE</span>
		    </div>
		        

	        <div id="bookcolumn1" style="width:280px;height:170px;margin-bottom:30px">
	          <img src="assets/images/books/{{book_data.relatedimage1}}" style="float:left;margin:4px;" />
	          <div style="font-size: 11px;padding-top: 10px">{{book_data.reltext1}}</div>
	          <a class="basic_text" href="/details/{{book_data.friendly_guid1}}">more details...</a>
	        </div>

	        <div id="bookdivider" style="border-top: solid .5px #CCCCCC;padding-top:35px;padding-bottom:15px"></div>
	        
	        <div id="bookcolumn2" style="float:left;width:280px;margin-bottom:30px">
	          <img src="assets/images/books/{{book_data.relatedimage2}}" style="float:left;margin:4px;" />
	          <div style="font-size: 11px;padding-top: 10px" >{{book_data.reltext2}}</div>
	          <a class="basic_text" href="/details/{{book_data.friendly_guid2}}">more details...</a>
	        </div>








		</div>



	</div>

<app-ftr></app-ftr>
