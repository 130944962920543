<div class="container" style="margin-top:50px;">

    
    <nav class="navbar navbar-expand-md navbar-light" style="padding:0px;background-color:#fff;">


        <img *ngIf="!isMobile" class="card-imgx" src="/assets/logo.jpg" alt="logox" routerLink="/" routerLinkActive="">
        <img *ngIf="isMobile" class="card-imgx" src="/assets/logo_mobile.jpg" alt="logox" routerLink="/" routerLinkActive="">
                
        <button class="navbar-toggler custom-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>


        <div class="collapse navbar-collapse" id="navbarNav">

          <ul class="navbar-nav mr-auto">
                <!--
                <li class="nav-item active">
                    <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                </li>
                -->
          </ul>


          <ul class="navbar-nav">
            
            
            <li class="nav-item" style="padding-top:4px;text-align: right;">
                    <a class="nav-link hdr-btn" routerLink="/" [routerLinkActive]="['is_active']" [routerLinkActiveOptions]="{exact: true}">Home</a>
            </li>


            <li class="nav-item" style="padding-top:4px;text-align: right;">
                <a class="nav-link hdr-btn" routerLink="/books" [routerLinkActive]="['is_active']" [routerLinkActiveOptions]="{exact: true}">Books</a>
            </li>


            <li class="nav-item" style="padding-top:4px;text-align: right;">
                <a class="nav-link hdr-btn" routerLink="/about" [routerLinkActive]="['is_active']" [routerLinkActiveOptions]="{exact: true}">About</a>
            </li>
            

            <li *ngIf="token_found==true" class="nav-item" style="padding-top:4px;text-align: right;">
                    <a class="nav-link hdr-btn" [routerLink]="'/' + token_user_name" [routerLinkActive]="['is_active']">{{token_user_name}}</a>
            </li>


            <div *ngIf="token_found==true">

                 <li class="nav-item dropdown">

                  <a class="nav-link dropdown-toggle hdr-btn" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="padding-top:12px;padding-left:25px;"><fa-icon [icon]="faUser"></fa-icon>&nbsp;&nbsp;</a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                        
                    <br/>
                    <a class="dropdown-item" (click)="logout_sessions()"><fa-icon [icon]="faSignOutAlt" [styles]="{'stroke': 'grey', 'color': 'grey'}"></fa-icon> Log Out</a>
                  </div>
                </li>


            </div>

            

            <!--
            <li class="nav-item" style="text-align: center;">
                <a class="nav-link" routerLink="/login">
                    <button type="button" class="btn btn-outline-light btn-sm">Register</button>
                </a>
            </li>
        	-->


            <!--
            <li class="nav-item">
              <a href="#" class="nav-link">Link 1</a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link">Link 2</a>
            </li>
            <li class="nav-item">
              <a href="#" class="nav-link">Link 3</a>
            </li>
            -->

          </ul>
        </div>
    </nav>

    <!--
    	<hr style="height:3px;border:none;color:#000;background-color:#000;" />
	-->


</div>
