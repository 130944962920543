import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HdrComponent } from './landing/hdr/hdr.component';
import { FtrComponent } from './landing/ftr/ftr.component';
import { HomeComponent } from './landing/home/home.component';
import { NotfoundComponent } from './landing/notfound/notfound.component';
import { BooksComponent } from './landing/books/books.component';
import { AboutComponent } from './landing/about/about.component';
import { DetailsComponent } from './landing/details/details.component';


import { BookApiService } from './_serviceAPI/book-api.service';
import { BookDataService } from './_serviceAPI/book-data.service';

import { TokenManager } from './_serviceAPI/token-manager.service';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CookiePolicyComponent } from './landing/cookie-policy/cookie-policy.component';



@NgModule({
  declarations: [
    AppComponent,
    HdrComponent,
    FtrComponent,
    HomeComponent,
    NotfoundComponent,
    BooksComponent,
    AboutComponent,
    DetailsComponent,
    CookiePolicyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    BrowserAnimationsModule
  ],
  providers: [TokenManager,BookApiService,
    BookDataService], 
  bootstrap: [AppComponent]
})
export class AppModule { }
