<div class="ui container" style="padding-top:100px;padding-bottom: 100px">
                  
                  <div class="ui one column stackable middle aligned center aligned grid">
                                      
                    <!-- LOGIN COLUMN -->
                    <div class="twelve wide column left aligned center_content" style="background-color: #fff; min-width: 360px; border-radius:20px; border:1px solid #C0C0C0;">
                    
                        <div style="text-align: center;">  
                                  <a routerLink="/">
                                    <img class="img-responsive" src="assets/official_logo.jpg" alt="Logo" />
                                  </a> 

                                  <br/>
                                  
                                  <div class="specialized_logo_font" style="padding-top: 20px;padding-bottom: 20px">SpringSociety.com</div>

                                   <h2>Cookie Policy</h2>
                        </div>



                        <div style="padding-left:30px;padding-top:40px;">
                                  <h4>COOKIES & OTHER TRACKING TECHNOLOGIES</h4>

                                      <p>SpringSociety service providers working on our behalf, and businesses who collect information on our pages, may automatically receive certain information associated with you and/or your device(s) as part of your use of the SpringSociety.com through browser or device-based tracking technologies such as cookies, pixels, tags, beacons, scripts, or other technology.</p>

                                      <p>A cookie is a small text file that is placed on your computer when you visit a website. Tracking pixels (sometimes referred to as web beacons or clear GIFs) are tiny electronic tags with a unique identifier that are sometimes embedded in websites, online ads and/or email, and that are designed to provide usage information like ad impressions (how many times an ad is viewed) or clicks, to measure popularity of the Services and associated advertising, and to access information from user cookies.</p>

                                      <p>The information we collect through online tracking technologies includes:</p>

                                      <p>Information about how you access SpringSociety.com: When you use SpringSociety.com, we automatically collect or receive some information about how you access the SpringSociety.com website, including the device type, operating system, and browser you use, and how fast or stable your internet connection is.</p>

                                      <p>Information about your activity on the SpringSociety.com website: information about your interactions with email messages we send you, such as which links you click on, and whether the messages were opened or forwarded.</p>

                                      <p>Unique Identifiers: IP addresses associated with the devices you use to access SpringSociety.com, Advertising IDs, Cookie IDs, and other unique identifiers.</p>

                                      <p>The information accessed through cookies and other tracking technologies is used along with the other information we collect for the purposes outlined in the Privacy Policies of the respective brands listed below, namely: Remembering your settings and choices and providing you with a customized experience on the SpringSociety.com website; Monitoring, analyzing, and improving the operation, delivery, performance and usability of the SpringSociety.com website; and Providing you with personalized ads based on your activity on the SpringSociety.com website.</p>

                                  <h4>MANAGE COOKIES AND OTHER TRACKING TECHNOLOGIES ON YOUR DEVICES</h4>

                                      <p>SpringSociety.com, service providers working on our behalf, and businesses who collect information on our pages, may automatically receive certain information associated with you and/or your device(s) as part of your use of the SpringSociety.com website through browser or device-based tracking technologies such as cookies, pixels, tags, beacons, scripts, or other technology.</p>

                                      <p>Please note that all devices and browsers are different. The methods described below may change at any time. We encourage you to always refer to the relevant instructions for your particular device or browser.</p>

                                      <table class="ui celled table" style="padding-top:30px;">
                                        <thead>
                                          <tr><th>Browser Name</th>
                                          <th>Instructions</th>
                                          <th>Source</th>
                                        </tr></thead>
                                        <tbody>
                                          <tr>
                                            <td data-label="Name">Google Chrome</td>
                                            <td data-label="Age">
                                              
                                              <ul>
                                                <li>At the top right, click “⋮” icon, and then Settings.</li>
                                                <li>At the bottom, click Advanced.</li>
                                                <li>Under “Privacy and security”, click Site settings and then Cookies.</li>
                                                <li>Turn Allow sites to save and read cookie data on or off.</li>
                                              </ul>

                                            </td>
                                            <td data-label="Job">
                                              <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop">https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop</a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td data-label="Name">Apple Safari</td>
                                            <td data-label="Age">
                                              On Mac:<br/>
                                              Safari > Preferences, click “Privacy”<br/>
                                              <br/>
                                              On iOS:<br/>
                                              Settings > Safari, then turn on “Block All Cookies”<br/>
                                            </td>
                                            <td data-label="Job">
                                              <a href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac https://support.apple.com/en-us/HT201265">https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac https://support.apple.com/en-us/HT201265</a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td data-label="Name">Mozilla Firefox</td>
                                            <td data-label="Age">
                                              <ul>
                                              <li>Click the menu button “☰” and select “Preferences”.</li>
                                              <li>Select the “Privacy &amp; Security” panel and go to the “Cookies and Site Data” section.</li>
                                              </ul>

                                            </td>
                                            <td data-label="Job">
                                              <a href="https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox">https://support.mozilla.org/en-US/kb/block-websites-storing-cookies-site-data-firefox</a>
                                            </td>
                                          </tr>
                                          <tr>
                                            <td data-label="Name">Microsoft Edge</td>
                                            <td data-label="Age">
                                              <ul>
                                              <li>More button “⋯” > Settings.</li>
                                              <li>Select “View Advanced Settings”.</li>
                                              <li>Under “Privacy and Services” > “Cookies”</li>
                                              </ul>

                                            </td>
                                            <td data-label="Job">
                                              <a href="https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">https://support.microsoft.com/en-us/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy</a>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table>



                                    
                        </div>
                                                                       


                    </div> <!-- login SEVEN WIDE - ONE COLUMN -->

                    <div class="ui column center_content" style="display: none;">
                        
                          <span>Dont have an </span><a routerLink="${tg.url('/register')}">account</a> <span>&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;</span>   <span>Forgot</span> <a routerLink="${tg.url('/forgot')}">password?</a>
                        
                    </div>




                  </div><!-- ONE COLUMN MIDDLE-->
              </div>  <!-- UI Container -->
