<app-hdr></app-hdr>


<div class="container" style="min-height:700px;">
  

  	<!-- Trending -->
	<div class="row justify-content-center" style="margin-top:20px;padding-left:60px;">	  
		<div style="display:block;">
			<img *ngIf="!isMobile" src="/assets/images/chairS.jpg" alt="CHAIR + BOOK = READ"/>
			<img *ngIf="isMobile" src="/assets/images/chairS_mobile.jpg" alt="CHAIR + BOOK = READ"/>

		</div>
	</div>


	<!-- Trending -->
	<div class="row justify-content-center" style="margin-top:30px;">
	  
		<div style="display:block;">
			<span style="font-size: 34px;font-family: 'Great Vibes', cursive;">Books Worth Sitting For</span>
		</div>
	</div>


	<div class="row" style="margin-top:40px;">
	    
	    <div class="col 4" style="text-align:center;margin-top:30px;margin-bottom:30px;">
	    	<div style="display:inline-block;width:300px;padding-right:7px;padding-left:7px" id="col1">
	              <span style="font-size: 24px;font-family: 'Bebas+Neue', cursive;">We Publish Books</span>
	              <br/><br/>
	              <img src="assets/images/bookmark.png" /><br/><br/>
	              <p class="document_flat" style="text-align:center;padding-top:0px">        
	                We are curating the writings of the web&#39;s most culturally astute individuals to bring you books which your local bookstore can&#39;t offer.        
	              </p>
	        </div>
	    </div>


	    <div class="col 4" style="text-align:center;margin-bottom:30px;">
	    	<div style="display:inline-block;width:300px;padding-right:7px;padding-left:7px" id="col2">
	            	<span style="font-size: 24px;font-family: 'Bebas+Neue', cursive;">Featured</span>
	            	<br/><br/>
	            	<p class="document_flat" style="text-align:center;padding-top:0px">
	              Based on Graetz&#39;s model of strategic thinking, Selling The Cow takes a look at Jack and The Bean Stalk as the archetype for selling technology.<br/><br/>
	              <img src="assets/images/featuredM.png" />
	            </p>
	          </div>
	    </div>


	    <div class="col 4" style="text-align:center;margin-top:30px;">
	    	<div style="display:inline-block;width:300px;padding-right:7px;padding-left:7px" id="col3">
	             <!--
	              <img border="0" src="images/hdr3.png" />
	              -->
	              <span style="font-size: 24px;font-family: 'Bebas+Neue', cursive;">NewsLetter</span>
	              <br/><br/>
	              <img src="assets/images/flash.png" /><br/><br/>
	              <p class="document_flat" style="text-align:center;padding-top:0px">
	                Do you want to hear from us?<br/>
	                Sign up for our newsletter 
	                <a class="modalbox" href="#inline">here</a>.<br/>
	                &nbsp;
	              </p>
	          </div>
	    </div>

	</div>




<app-ftr></app-ftr>