import { Injectable } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BookApiService } from './book-api.service';

@Injectable()
export class BookDataService {

  constructor(
    private http: HttpClient,
    private api: BookApiService    
  ) { }




  getBookDetails(n_guid:string) {
    return this.api.get_book_details(n_guid)
  }

  getBooks() {
    return this.api.get_books()
  }

  /*
  logoutSession(n_type:string) {
    return this.api.logoutSession(n_type)    
  }
  */

}