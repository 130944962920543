import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BookDataService } from '../../_serviceAPI/book-data.service';
import { Books, url_list } from '../../_model/books';

import {ActivatedRoute} from '@angular/router';



@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  books: Books[];
  book_data: Books;


  friendly_guid:string = '555';

  url_list: url_list[];

  page_loading:boolean = true;

  constructor(
  	private route:ActivatedRoute,
  	private http: HttpClient, 
    private bookService:BookDataService
  ) { }

  ngOnInit(): void {


  	    this.route.params.subscribe(params =>
            this.friendly_guid = params['nguid'])


  		this.bookService.getBookDetails(this.friendly_guid)
	            .subscribe(
	                data => {

	                		if (data['status'][0]['msg_status'] != "success") {
		                	this.page_loading = false;

                        alert("ERROR - " + data['status'][0]['msg_data']['msg_description'] );

		                	} else {
				                this.books = data['data'];

				                this.book_data = data['data'][0];

				                /*
				                console.log('test123');
				                console.log(this.book_data);
			                
				                console.log(this.book_data["content"]);
				                console.log(this.book_data["reltext1"]);
				                console.log(this.book_data["reltext2"]);
				                
				                console.log(this.book_data["title"]);
				                console.log(this.book_data["author"]);
				                console.log(this.book_data["hardcover"]);
				                
				                console.log(this.book_data["paperback"]);
				                console.log(this.book_data["digitaledition"]);
				                console.log(this.book_data["publisheddate"]);

				                console.log(this.book_data["status"]);

				                console.log(this.book_data["isbn"]);
				                */

				                // this.n_total_categories = data['data']['data-meta']['total_books'];
				                // this.n_total_urls = data['data']['data-meta']['total_links'];	

                        	    // this.n_ad_title = data['data']['data-meta']['advert']['title'];
                        		// this.n_ad_desc = data['data']['data-meta']['advert']['description'];
                        		// this.n_ad_url = data['data']['data-meta']['advert']['url'];
                        		// this.n_ad_image_src = data['data']['data-meta']['advert']['image_src'];

                        		this.page_loading = false;
		                	}
	                },
	                error => {
                      this.page_loading = false;
	                    alert("ERROR - " + "a network error occurred");
	                });

  }

}