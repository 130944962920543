import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './landing/home/home.component';
import { BooksComponent } from './landing/books/books.component';

import { NotfoundComponent } from './landing/notfound/notfound.component';
import { DetailsComponent } from './landing/details/details.component';
import { AboutComponent } from './landing/about/about.component';
import { CookiePolicyComponent } from './landing/cookie-policy/cookie-policy.component';


const routes: Routes = [
	{ path: '', component: HomeComponent},
	{ path: 'books', component: BooksComponent},
	{ path: 'details/:nguid', component: DetailsComponent},
	{ path: 'about', component: AboutComponent},
	{ path: 'cookie-policy',  component: CookiePolicyComponent},  
	{ path: '404', component: NotfoundComponent},
	{ path: '**', redirectTo: '/404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
