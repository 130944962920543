<app-hdr></app-hdr>


<div class="container" style="min-height:700px;">
  
  	<!-- Header 
  	<div class="row" style="margin-top:20px;">
		<div class="col">
			<span style="color:#CCCCCC;font-family:verdana;font-weight:bold;font-size:18px;padding: 30px 10px 5px 0px;">Available Books</span>
		</div>
	</div>
	-->


	<!-- Floating Columns -->
	<div class="row" style="margin-top:30px;min-height:600px">

		<div class="col-12 text-center" style="padding-top:100px;" >
			<img src="assets/images/blank_book.jpg" style="margin:4px;"/>
			<div class="mNFhdr">404</div>
			<div class="mNFtxt">Not Found .... You must be lost</div>		
		</div>


	</div>

<app-ftr></app-ftr>