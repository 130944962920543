import { Injectable } from '@angular/core';
// import { Http, Headers, Response } from '@angular/http';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';

// import * as jwt_decode from "jwt-decode";
import * as jwt from "angular2-jwt-simple";
import jwt_decode from 'jwt-decode';


@Injectable()
export class TokenManager {

    constructor(private _http: HttpClient) {}

    private ftrTokenData:string = 'springsociety_app_token';   //unique_id

    private store(content:Object) {
        localStorage.setItem(this.ftrTokenData, JSON.stringify(content));
    }


    private retrieve() {
        let storedToken:string = localStorage.getItem(this.ftrTokenData);
        if(!storedToken) throw 'no token found';
        return storedToken;
    }


    public getTokenHeaders(){
        
        let n_state = this.getToken();

        // login found get token headers
        let headers = new Headers();
        let headersX = new HttpHeaders();

        if (n_state) {
            
            headersX = headersX.set('Access-Control-Allow-Origin', '*')
            .set('X-Auth-Token', n_state["user_access_token"])
            .set('X-Requested-By', n_state["user_guid"])
            .set('Accept', 'application/json');

        } else {

            headersX = headersX.set('Access-Control-Allow-Origin', '*')
            .set('X-Auth-Token', '460318af-f292-4bcb-bc64-f3d2b7743029')
            .set('Accept', 'application/json');

        }

        return headersX;

    }


    public getTokenVendorString(){
        var h = { 'X-Auth-Token': '460318af-f292-4bcb-bc64-f3d2b7743029', 'X-Requested-By':'-no-user-static-vendor', 'Access-Control-Allow-Origin': '*' }
        return h
    }


    // UPDATE TOKEN - (if user settings change)
    public updateToken(content:Object) {
        var secret = 'secret';
        var token = jwt.encode(content, secret);
        localStorage.setItem(this.ftrTokenData, token.toString());
    }




    // UPDATE TOKEN Value - (if user settings change)
    public updateTokenValue(n_key:string, n_value:string) {


        try {
            let storedToken:string = localStorage.getItem(this.ftrTokenData);            
            let tokenData = this.getDecodedAccessToken(storedToken); // decode token

            // show TokenData Key (make sure your updating the right one)
            tokenData[n_key] = n_value

            // Update Token
            var secret = 'secret';
            var token = jwt.encode(tokenData, secret);
            localStorage.setItem(this.ftrTokenData, token.toString());
        }
        catch(err) {
            console.error(err);
        }

    }



    // STORE TOKEN
    public storeToken(content:Object) {
        localStorage.setItem(this.ftrTokenData, content.toString());
    }



    // GET TOKEN
    public getToken() {
        
        let token = null;
        try {
            let storedToken:string = localStorage.getItem(this.ftrTokenData);
            
            let tokenData = this.getDecodedAccessToken(storedToken); // decode token
            
            let storedTokenGet = tokenData;
            token = storedTokenGet;

        }
        catch(err) {
            console.error(err);
        }
        return token;
    }

    public isLoggedIn() {

        // determine if user logged in
        try {


            if (localStorage.getItem(this.ftrTokenData) === null) {
              return false; 
            } else {
              return true;
            }

        }
        catch(err) {
            console.error(err);
        }
        
        return false;
    }



    // DELETE TOKEN ON LOGOUT
    public deleteToken() {
        localStorage.clear();
        localStorage.removeItem(this.ftrTokenData);
    }


    private getDecodedAccessToken(token: string): any {
        try{
            return jwt_decode(token);
        }
        catch(Error){
            return null;
        }
    }




}
