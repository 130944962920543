<div class="container" style="margin-top:50px;">

	<hr style="height:1px;border:none;color:#000;background-color:#c8c8c8;" />


  <div class="row">

    <div class="col 8" style="text-align:left;">
      <span class="small_font" >&#xa9; {{curryear}} SpringSociety Publishing</span>
    </div>

    <div class="d-none d-sm-block col-sm-4" style="text-align:right;">
      <span class="small_font">Chair + Books = Read</span>      
    </div>

  </div>


</div>