import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ftr',
  templateUrl: './ftr.component.html',
  styleUrls: ['./ftr.component.css']
})
export class FtrComponent implements OnInit {


  curryear:number = new Date().getFullYear();

  constructor() { }

  ngOnInit(): void {
  }


  switch_language(n_lang_type:string) {


  	switch (n_lang_type) {
  		case "sp":
  			alert("aun no mi gente ... próximamente");
  			break;
  		case "fr":
  			alert("pas encore mon peuple ... bientôt disponible");
  			break;  		
  		default:
  			alert("not yet my friend ... coming soon");
  			break;
  	}

  }



}
