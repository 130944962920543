import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

	deviceInfo = null;
	isMobile: boolean = false;


  constructor(
  	private deviceService: DeviceDetectorService,
  ) {
  this.epicFunction()
  }

  ngOnInit(): void {


        // Consent
       let cc = window as any;
         cc.cookieconsent.initialise({
           palette: {
             popup: {
               background: "#808080"
             },
             button: {
               background: "#ffe000",
               text: "#164969"
             }
           },
           theme: "classic",
           content: {
             message: "We use cookies on our website to optimize performance.  To learn more about how we use cookies please visit:",
             dismiss: "Accept",
             link: "Our Policy",
             href: "/cookie-policy" 
           }
         });


  }


  epicFunction() {
      // console.log('hello `Home` component');
      this.deviceInfo = this.deviceService.getDeviceInfo();
      this.isMobile = this.deviceService.isMobile();
      const isTablet = this.deviceService.isTablet();
      const isDesktopDevice = this.deviceService.isDesktop();
      // console.log(this.deviceInfo);
      // console.log(this.isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
      // console.log(isTablet);  // returns if the device us a tablet (iPad etc)
      // console.log(isDesktopDevice); // returns if the app is running on a Desktop browser.
    }

}
