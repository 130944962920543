<app-hdr></app-hdr>


<div class="container" style="min-height:700px;">


	<div *ngIf="page_loading" class="row" style="margin-top:0px;min-height:600px;max-width:1100px;">
		<div class="col-12" style="padding-top: 250px;">				
				<center>
					<mat-spinner mode="indeterminate"></mat-spinner>
				</center>
		</div>
	</div>

  
  	<!-- Header -->
  	<div class="row" style="margin-top:20px;">
		<div class="col">
			<span style="color:#CCCCCC;font-family:verdana;font-weight:bold;font-size:18px;padding: 30px 10px 5px 0px;">Available Books</span>
		</div>
	</div>





	<div class="row" style="margin-top:30px;">

		<div *ngFor="let c of books; let i = index" class="col-xs-12 col-sm-6 col-md-6 col-md-6" style="margin-bottom: 50px;">
		
			<div class="row">
				
				<div class="col-4">
					<img src="./assets/images/books/{{c.imgmedium}}" class="img-fluid"/>
				</div>

				<div class="col-8" style="background-color:#fff;">
					
					<div class="availbold"><b>{{c.title}}</b></div>
		  			<div class="availnorm" style="margin-top:8px;">Author:&nbsp;{{c.author}}</div>
		  			<div class="availnorm">Publish Date:&nbsp;{{c.pubdate}}</div>
		  				
					<div class="availnorm" style="margin-top:15px;">Status:&nbsp;{{c.availability}}</div>					

					<p class="document_flat" style="margin-top:15px;">
						  {{c.shorttext}}
					</p>
							
					<a class="small blue awesome" href="/details/{{c.friendly_guid}}" id="login_button" title="{{c.friendly_guid}}">Details</a>	
					<br/>

				</div>
			</div>

		</div>

	</div>


<app-ftr></app-ftr>
